import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';

function Index() {

  
  useEffect(() => {

    const PI2 = Math.PI * 2;

    const COLORS = [
        { r: 80, g: 174, b: 210 },
        { r: 202, g: 223, b: 194 },
        { r: 187, g: 250, b: 254 },
        { r: 123, g: 103, b: 226 },
        { r: 232, g: 140, b: 220 }
    ];

    class App {
        constructor() {
            this.canvas = document.createElement('canvas');
            document.body.appendChild(this.canvas);
            this.ctx = this.canvas.getContext('2d');
  
            this.pixelRatio = (window.devicePixelRatio > 1) ? 2 : 1;
  
            this.totalParticles = 50;
            this.particles = [];
            this.maxRadius = 900;
            this.minRadius = 400;
            
            window.addEventListener('resize', this.resize.bind(this), false);
            this.resize();
  
            window.requestAnimationFrame(this.animate.bind(this));
        }
  
        resize() {
            this.stageWidth = document.body.clientWidth;
            this.stageHeight = document.body.clientHeight;
  
            this.canvas.width = this.stageWidth * this.pixelRatio;
            this.canvas.height = this.stageHeight * this.pixelRatio;
            this.ctx.scale(this.pixelRatio, this.pixelRatio);
  
            this.ctx.globalCompositeOperation = 'saturation';
  
            this.createParticles();
        }
  
        createParticles() {
            let curColor = 0;
            this.particles = [];
  
            for (let i= 0; i < this.totalParticles; i++) {
                const item = new GlowParticle(
                    Math.random() * this.stageWidth,
                    Math.random() * this.stageHeight,
                    Math.random() * (this.maxRadius - this.minRadius) + this.minRadius,
                    COLORS[curColor]
                );  
  
                if (++curColor >= COLORS.length) curColor = 0;
                
                this.particles[i] = item;
            }
        }
  
        animate() {
            window.requestAnimationFrame(this.animate.bind(this));
  
            this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight);
  
            for (let i = 0; i < this.totalParticles; i++) {
                const item = this.particles[i];
                item.animate(this.ctx, this.stageWidth, this.stageHeight)
            }
        }
    }
    class GlowParticle {
        constructor(x, y, radius, rgb) {
            this.x = x;
            this.y = y;
            this.radius = radius;
            this.rgb = rgb;
  
            this.vx = Math.random() * 6;
            this.vy = Math.random() * 6;
  
            this.sinValue = Math.random();
        }
  
        animate(ctx, stageWidth, stageHeight) {
            this.sinValue += 0.1;
  
            this.radius += Math.sin(this.sinValue);
  
            this.x += this.vx;
            this.y += this.vy;
  
            if (this.x < 0) {
                this.vx *= -1;
                this.x += 10;
            } else if (this.x > stageWidth) {
                this.vx *= -1;
                this.x -= 10;
            } 
  
            if (this.y < 0) {
                this.vy *= -1;
                this.y += 10;
            } else if (this.y > stageHeight) {
                this.vy *= -1;
                this.y -= 10;
            } 
  
            ctx.beginPath();
            const g = ctx.createRadialGradient(
                this.x,
                this.y,
                this.radius * 0.05,
                this.x,
                this.y,
                this.radius
            );
            g.addColorStop(0, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 1)`);
            g.addColorStop(1, `rgba(${this.rgb.r}, ${this.rgb.g}, ${this.rgb.b}, 0)`);
  
            ctx.fillStyle = g;
            ctx.arc(this.x, this.y, this.radius, 0, PI2, false);
            ctx.fill();
        }
    }

    new App();
  }, []);

  return (
    <main>
      <Helmet>
        <title>Isabella Rebasso</title>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap" rel="stylesheet" />
      </Helmet>
      <p>Isabella<br />Rebasso</p>

      <div id="info">
          E-Mail
          <br />
          <a id="mail" href="mailto:i.rebasso@uva.nl" target="_blank" rel="noopener noreferrer">i.rebasso@uva.nl </a>
          <br />
          <br />
  

          Twitter
          <br />
          <a id="tw" href="https://twitter.com/Isabella_Reb" target="_blank" rel="noopener noreferrer">@Isabella_Reb</a>
          <br />  
          <br />
  

          HotPoliticsLab
          <br />
          <a id="lab" href="http://www.hotpolitics.eu/" target="_blank" rel="noopener noreferrer">Click here</a>

      </div>
    </main>
  );
}

export default Index;
